<template>
  <div class="checkout">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
        </template>
        <template #title>
          <div class="navbar-title">Checkout</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="content">
      <van-image width="100%" fill="contain" :src="require('./img/img.png')" />
      <div class="bg">
        <div class="top" @click="to_address"></div>
      </div>
    </div>

    <div class="btn" @click.stop="to_result">
      <van-image width="100%" fill="contain" :src="require('./img/btn.png')" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    to_address() {
      this.$router.push({ path: "/address" });
    },
    to_result() {
      this.$router.push({ path: "/result" });
    },
  },
};
</script>

<style lang="scss">
.checkout {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;
    margin-bottom: 0.625rem;

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .navbar-title {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      color: #000000;
      font-weight: bold;
      position: relative;
    }
  }

  .van-tabs {
    .van-tabs__line {
      bottom: 25px;
    }
  }

  .content {
    position: relative;
    margin-bottom: 10px;

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .top {
        height: 15.625rem;
      }

      .bg-btn {
        width: 100%;
        height: 200px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .btn {
    position: sticky;
    bottom: 0;
  }
}
</style>